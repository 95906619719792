<template>
  <div class="page-layout__inner">
    <div :class="'page-layout__filter' + (filterSettings.visible ? '' : ' w-auto')">
      <section :class="'section  section--shadow-0  section--p-0' + (filterSettings.visible ? ' h-100' : '')">
        <button @click="filterSettings.visible = true" :class="'section__filter-btn' + (filterSettings.visible ? ' section__filter-btn--hidden' : '')">
          <svg class="section__filter-icon">
            <use xlink:href="#icon__filter"></use>
          </svg>
        </button>
        <div :class="'section__filter' + (filterSettings.visible ? '' : ' section__filter--hidden')">
          <div class="section__header">
            <h2 class="section__title">
              <svg class="section__filter-icon">
                <use xlink:href="#icon__filter"></use>
              </svg>
              {{ getTranslation('filter') }}
            </h2>

            <div class="section__controls">
              <div class="section__controls-group">
                <a @click="filterSettings.visible = false" class="btn btn-link " href="javascript:void(0)">{{ getTranslation('hide') }}</a>
                <a class="btn  btn-link" href="javascript:void(0)" @click="resetFilter">{{ getTranslation('reset') }}</a>
              </div>
            </div>
          </div>
          <div class="section__body">
            <div class="form-group">
              <form method="get" @submit.prevent="fetchApplications(1)">
                <label class="form__label form__label--block">{{ getTranslation('search') }}</label>
                <div class="field-advanced">
                  <div class="field-advanced__inner">
                    <div class="field-advanced__field-wrap">
                      <input v-model="filterForm.query" class="field  field--full-width  field--bg-grey  js-borderOnFocus" type="text" :placeholder="getTranslation('search')">
                    </div>

                    <div class="field-advanced__right">
                      <button class="btn btn-primary">
                        <svg class="btn__icon">
                          <use xlink:href="#icon__search"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <ServiceCategoryComponent v-model="filterForm.service_category_id"></ServiceCategoryComponent>

            <ServiceOptionComponent v-model="filterForm.service_option_id" :full="true"/>


            <CountrySelect
                v-model="filterForm.origin_country_id"
                form-label="originCountry"
            ></CountrySelect>

            <CountrySelect
                v-model="filterForm.destination_country_id"
                form-label="destinationCountry"
            >
            </CountrySelect>

            <div class="form-group">
              <label class="form__label--block form__label">{{ getTranslation('status') }}</label>
              <VSelect
                  :options="formOptions.statuses"
                  :reduce="option=>option.id"
                  label="title"
                  v-model="filterForm.status"
                  :placeholder="getTranslation('selectFromList')"
              />
            </div>

            <div class="form-group">
              <label class="form__label--block form__label">{{ getTranslation('requestStatus') }}</label>
              <VSelect
                  :options="formOptions.requestStatuses"
                  :reduce="option=>option.id"
                  label="title"
                  v-model="filterForm.request_status"
                  :placeholder="getTranslation('selectFromList')"
                  @input="fetchApplications(1)"
              />
            </div>

            <div class="form-group">
              <label class="form-check-label form__label text-capitalize">
                {{ getTranslation('expressCargo') }}
              </label>
              <div class="form-check mt-2">
                <input class="form-check-input" type="checkbox" v-model="filterForm.express" @change="fetchApplications(1)" id="express">
                <label class="form-check-label form__label" for="express">
                  {{ getTranslation('expressCargo') }}
                </label>
              </div>
            </div>

            <div v-if="!$store.getters['user/getUser'].isAgent" class="form-group">
              <label class="form-check-label form__label text-capitalize">
                {{ getTranslation('callBack') }}
              </label>
              <div class="form-check mt-2">
                <input class="form-check-input" type="checkbox" v-model="filterForm.is_call_requested" id="is_call_requested">
                <label class="form-check-label form__label" for="is_call_requested">
                  {{ getTranslation('callRequested') }}
                </label>
              </div>
            </div>

            <div v-if="!$store.getters['user/getUser'].isAgent" class="form-group">
              <label class="form-check-label form__label text-capitalize">
                {{ getTranslation('clientPayment') }}
              </label>
              <div class="form-check mt-2">
                <input class="form-check-input" type="checkbox" v-model="filterForm.is_client_paid" id="defaultCheck1">
                <label class="form-check-label form__label" for="defaultCheck1">
                  {{ getTranslation('isClientPaid') }}
                </label>
              </div>
              <div class="form-check mt-2">
                <input class="form-check-input" type="checkbox" v-model="filterForm.clientNotPaid" id="clientNotPaid">
                <label class="form-check-label form__label" for="clientNotPaid">
                  {{ getTranslation('isClientNotPaid') }}
                </label>
              </div>
            </div>

            <div v-if="!$store.getters['user/getUser'].isAgent" class="form-group">
              <label class="form-check-label form__label text-capitalize">
                {{ getTranslation('providerPayment') }}
              </label>
              <div class="form-check mt-2">
                <input class="form-check-input" type="checkbox" v-model="filterForm.is_provider_paid" id="is_provider_paid">
                <label class="form-check-label form__label" for="is_provider_paid">
                  {{ getTranslation('providerPaid') }}
                </label>
              </div>
              <div class="form-check mt-2">
                <input class="form-check-input" type="checkbox" v-model="filterForm.providerNotPaid" id="providerNotPaid">
                <label class="form-check-label form__label" for="providerNotPaid">
                  {{ getTranslation('providerNotPaid') }}
                </label>
              </div>
            </div>

            <div class="form-group">
              <label class="form__label  form__label--block">{{ getTranslation('shipmentDate') }}</label>
              <div class="form__tillfrom-row">
                <date-picker v-model="filterForm.shipment_date_from" valueType="format"></date-picker>
                <div class="form__tillfrom-sep"></div>
                <date-picker v-model="filterForm.shipment_date_to" valueType="format"></date-picker>
              </div>
            </div>

            <div v-if="!$store.getters['user/getUser'].isAgent" class="form-group">
              <label class="form__label  form__label--block">{{ getTranslation('addedDate') }}</label>
              <div class="form__tillfrom-row">
                <date-picker v-model="filterForm.added_date_from" valueType="format"></date-picker>
                <div class="form__tillfrom-sep"></div>
                <date-picker v-model="filterForm.added_date_to" valueType="format"></date-picker>
              </div>
            </div>

          </div>
        </div>
      </section>
    </div>
    <div class="page-layout__content">
      <div class="row col-md-12">
        <p class="text-muted mb-3">{{ getTranslation('resultsFoundWithQuantity', pagination.total) }}</p>
        <div class="table-responsive table-3-wrapper">
          <table class="table table-borderless">
            <thead class="table-3__head ">
            <tr class="table-3__row">
              <th class="table-3__cell  table__hcell" style="width: 0.1%">#</th>
              <th class="table-3__cell  table__hcell" style="width: 5%">{{ getTranslation('code') }}</th>
              <th class="table-3__cell  table__hcell" style="width: 10%">{{ getTranslation('title') }}</th>
              <th class="table-3__cell  table__hcell" style="width: 10%">{{ getTranslation('serviceCategory') }}</th>
              <th class="table-3__cell  table__hcell" style="width: 7%">{{ getTranslation('destinationCountry') }}</th>
              <th class="table-3__cell  table__hcell" style="width: 7%">{{ getTranslation('shipmentDate') }}</th>
              <th class="table-3__cell  table__hcell" style="width: 7%">{{ getTranslation($store.getters['user/getUser'].isAgent ? 'expirationTime' : 'addedDate') }}</th>
              <th class="table-3__cell  table__hcell" style="width: 7%">{{ getTranslation('status') }}</th>
            </tr>
            </thead>
            <tbody class="table-3__body">
            <tr v-for="item in items" class="table-3__row table__link-row" v-bind:key="item.id" @click="()=>{$router.push({name:'application.get',params:{id:item.id}})}">
              <td class="table-3__cell">{{ item.id }}</td>
              <td class="table-3__cell"> {{ item.code }}</td>
              <td class="table-3__cell">{{ item.title }}</td>
              <td class="table-3__cell"> {{ item.service_category.title }}</td>
              <td class="table-3__cell"> {{ item.destination_country.title }}</td>
              <td class="table-3__cell"> {{ item.shipment_date }}</td>
              <td class="table-3__cell">
                <span v-if="!$store.getters['user/getUser'].isAgent">{{ item.creation_date }}</span>
                <span v-else>{{ item.expirationDate }}</span>
              </td>
              <td class="table-3__cell">
              <span class="badge rounded-pill"
                    :class="{'badge-primary':item.status === 'CONFIRMED' || item.status==='AGENT_CONFIRMED','badge-success':item.status === 'NEW' || item.status==='AGENT_NEW','badge-warning':item.status === 'WAITING_PROVIDER'
                    || item.status==='AGENT_SELECTED','badge-danger':item.status === 'REJECTED' || item.status === 'AGENT_REJECTED' || item.status === 'AGENT_EXPIRED'}">
                {{ getTranslation('APPLICATION_' + item.status) }}
              </span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <CPagination
            v-if="pagination.last_page > 1"
            class="mt-5"
            :active-page.sync="pagination.current_page"
            :pages="pagination.last_page"
            :limit="10"
        />
      </div>


    </div>

    <modal
        :title="getTranslation('report')"
        :show.sync="showReportModal"
        :centered="false"
        @modalCancel="closeReportModal"
        cancel-button-label="close"
        ok-button-label="outputReport"
        @clickOK="report"
    >
      <CForm>
        <div class="form-group">
          <label class="form__label  form__label--block">{{ getTranslation('clientPayment') }}</label>
          <div class="form__tillfrom-row">
            <date-picker v-model="reportFilter.date_from" valueType="format"></date-picker>
            <div class="form__tillfrom-sep"></div>
            <date-picker v-model="reportFilter.date_to" valueType="format"></date-picker>
          </div>
        </div>
      </CForm>
    </modal>
  </div>
</template>

<script>
import CountrySelect from "@/components/shared/CountrySelect";
import ServiceCategoryComponent from "@/components/shared/ServiceCategoryComponent";
import ServiceOptionComponent from "@/components/shared/ServiceOptionComponent";
import documentMixin from "@/components/mixins/document";

export default {
  name: "Index",
  mixins: [ documentMixin ],
  components: { ServiceOptionComponent, ServiceCategoryComponent, CountrySelect },
  data() {
    return {
      showReportModal: false,
      filterSettings: {
        visible: true
      },
      items: [],
      pagination: {
        total: 1,
        last_page: 1,
        current_page: 1
      },
      filterForm: {
        status: this.$route.query.status || ''
      },
      reportFilter: {},
      formOptions: {
        statuses: [],
        requestStatuses: [],
        clientStatuses: []
      }
    }
  },
  created() {

    let actions = [];

    if ( this.hasPermission('application.create') )
      actions.push(
          {
            label: this.getTranslation('addApplication'),
            action: () => {
              this.$router.push({ name: 'application.create' }).catch(() => {
              });
            },
            icon: {
              icon: '#icon__shape-add'
            }
          }
      );

    if ( this.hasPermission('application.reports') )
      actions.push(
          {
            label: this.getTranslation('report'),
            action: this.openReportModal,
            icon: {
              icon: '#icon__file-text'
            }
          }
      );

    this.setHeadings({
      title: this.getTranslation('applications'),
      breadcrumbs: [
        {
          to: { name: 'application.getList' },
          text: this.getTranslation('applications')
        }
      ],
      actions: actions
    });

    this.fetchApplications(this.pagination.current_page);
    this.fetchFormOptions();
  },
  watch: {
    'pagination.current_page'(val) {
      this.fetchApplications(val);
    },
    'filterForm.origin_country_id'() {
      this.fetchApplications(1);
    },
    'filterForm.destination_country_id'() {
      this.fetchApplications(1);
    },
    'filterForm.service_category_id'() {
      this.fetchApplications(1);
    },
    'filterForm.status'() {
      this.fetchApplications(1);
    },
    'filterForm.is_client_paid'() {
      this.fetchApplications(1);
    },
    'filterForm.clientNotPaid'() {
      this.fetchApplications(1);
    },
    'filterForm.is_provider_paid'() {
      this.fetchApplications(1);
    },
    'filterForm.providerNotPaid'() {
      this.fetchApplications(1);
    },
    'filterForm.service_option_id'() {
      this.fetchApplications(1);
    },
    'filterForm.is_call_requested'() {
      this.fetchApplications(1);
    },
    'filterForm.added_date_from'() {
      this.fetchApplications(1);
    },
    'filterForm.added_date_to'() {
      this.fetchApplications(1);
    },
    'filterForm.shipment_date_to'() {
      this.fetchApplications(1);
    },
    'filterForm.shipment_date_from'() {
      this.fetchApplications(1);
    },
  },

  methods: {

    report() {
      this.reportFilter.format = this.formats.excel;
      let config = this.getAxiosConfig(this.reportFilter)

      this.axios.get(this.$urls.application.reports.url, config)
          .then(response => {
            this.createDownloadLink(response, 'report', this.formats.excel);
          }).catch(() => {
      });
    },
    openReportModal() {
      this.showReportModal = true;
    },
    closeReportModal() {
      this.showReportModal = false;
    },
    fetchApplications(page) {

      this.filterForm.page = page;

      let params = { ...this.cleanObject(this.filterForm) };

      params = this.parseParams(params);


      this.axios.get(this.$urls.application.applications.url, { params: params })
          .then(response => {
            this.items = response.data.data.items;
            this.pagination = response.data.data.pagination;
          }).catch(() => {
      });
    },
    parseParams(params) {
      if ( params.is_client_paid === true )
        params.is_client_paid = 1;

      if ( params.express === true )
        params.express = 1;
      else
        params.express = null;

      if ( params.is_client_paid === false )
        params.is_client_paid = null;

      if ( params.clientNotPaid === true )
        params.is_client_paid = 0;

      if ( params.is_provider_paid === true )
        params.is_provider_paid = 1;

      if ( params.is_provider_paid === false )
        params.is_provider_paid = null;

      if ( params.providerNotPaid === true )
        params.is_provider_paid = 0;

      if ( params.is_call_requested === true )
        params.is_call_requested = 1;

      if ( params.is_call_requested === false )
        params.is_call_requested = null;

      return params;
    },
    fetchFormOptions() {
      this.axios.get(this.$urls.application.formOptions.url)
          .then(response => {
            this.formOptions.statuses = response.data.data.statuses.map(item => {
              return {
                title: this.getTranslation('APPLICATION_' + item),
                id: item
              };
            });
            this.formOptions.requestStatuses = response.data.data.requestStatuses.map(item => {
              return {
                title: this.getTranslation('REQUEST_' + item),
                id: item
              };
            });
          }).catch(() => {
      });
    },
    resetFilter() {
      this.filterForm = {
        status: this.$route.query.status || ''
      };
    }
  }

}
</script>

<style scoped>

</style>